<template>
  <div class="flex items-center my-1">
    <i :class="`${icon} fit-icon ${iconColor}`" />
    <span class="pl-3 font-medium text-14px text-oLightGray">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'FeatureIconTypography',
  props: {
    text: {
      type: [String, Number],
      required: false,
    },
    icon: {
      type: [String, Number],
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'light-green',
      validator(value) {
        return ['light-green', 'green'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.fit-icon {
  &.light-green {
    color: #00d092;
  }
}
</style>
